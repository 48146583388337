import React from "react"
import Styled from "styled-components"

const Text = Styled.div`
    position: fixed;
    top: ${({ top }) => (top ? `${top}%` : `50%`)};
    left: 50%;
    transform:translateX(-50%);
    h2 {
        font-size: ${({ size }) => (size ? `${size}px` : `50px`)};
        width: 600px;
        text-transform: uppercase;
        transform: rotate(90deg);
        color: rgba(0,0,0,0.1);
    }
    @media screen and (max-width: 767px) {
        left: 90%;
    }
`
const AbsText = ({ top, size, children }) => (
  <Text top={top} size={size}>
    <h2>{children}</h2>
  </Text>
)

export default AbsText

import React from "react"
import { graphql, Link } from "gatsby"
import Styled from "styled-components"
import Img from "gatsby-image"

import Layout from "../../../components/Layout"
import SEO from "../../../components/Seo"
import RuledBreak from "../../../components/RuledBreak"
import AbsText from "../../../components/AbsText"
import { ButtonTrace } from "../../../components/Button"

const Options = Styled.div`
    text-align: left;
`
const OptionTitle = Styled.h2`
    display: inline-block;
    color: white;
    background-color: black;
    padding: 5px;
    text-transform: uppercase;
`
const OptionList = Styled.ul`
    padding-left: 0;
    list-style: none;
    li {
        padding-top: 10px;
        font-size: 20px;
        text-transform: uppercase;
    }
`
const OptionLink = Styled(Link)`
    cursor: pointer;
    display: table;
    &:hover {
        div {
            opacity: 0.6;
        }
    }
`
const OptionImg = Styled(Img)`
    width: 300px;
`
const FAQQ = Styled.h2`
    text-transform: uppercase;
`
const FAQA = Styled.h3`
    tex-transform: uppercase;
    font-weight: bold;
`

const Memberships = ({ data }) => {
  const headerImg = data.header.edges[0].node.childImageSharp.fluid
  const optionsBasic = data.optionsBasic.edges[0].node.childImageSharp.fluid
  const optionsExpert = data.optionsExpert.edges[0].node.childImageSharp.fluid
  return (
    <Layout
      fluid={headerImg}
      alt={"Cali Reeves Fan Like a Pro"}
      height={500}
      mobile={300}
    >
      <SEO title="Memberships" keywords={["Education", "Memberships"]} />
      {/* ----------------------------------------------------------------------- */}
      <div className="row">
        <div className="col-md-12">
          <RuledBreak>Membership Options</RuledBreak>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
      <AbsText>Membership Options</AbsText>
      {/* ----------------------------------------------------------------------- */}
      <div className="row">
        <div className="col-md-6 d-flex justify-content-center my-5">
          <Options>
            <OptionTitle>Basic</OptionTitle>
            <OptionList>
              <li>1 x Video Per Month</li>
              <li>10 % Off Existing Mini Courses</li>
              <li>15 % Off Kiwi Kulture Apparel</li>
            </OptionList>
            <OptionLink to="/education/memberships/basic">
              <OptionImg
                fluid={optionsBasic}
                alt="Membership Basic Option"
                imgStyle={{ objectFit: "contain" }}
              />
            </OptionLink>
            <ButtonTrace
              to="/education/memberships/basic"
              btn={3}
              className="mt-3"
            >
              Join Basic
            </ButtonTrace>
          </Options>
        </div>
        <div className="col-md-6 d-flex justify-content-center my-5">
          <Options>
            <OptionTitle>Expert</OptionTitle>
            <OptionList>
              <li>2 x Video Per Month</li>
              <li>15 % Off Existing Mini Courses</li>
              <li>20 % Off Kiwi Kulture Apparel</li>
            </OptionList>
            <OptionLink to="/education/memberships/expert">
              <OptionImg
                fluid={optionsExpert}
                alt="Membership Expert Option"
                imgStyle={{ objectFit: "contain" }}
              />
            </OptionLink>
            <ButtonTrace
              to="/education/memberships/expert"
              btn={3}
              className="mt-3"
            >
              Join Expert
            </ButtonTrace>
          </Options>
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-6 d-flex flex-column justify-content-center my-5">
          <FAQQ>What is the basic membership</FAQQ>
          <FAQA>
            The basic membership is perfect for beginner volume lash artists or
            volume lash artists with a smaller budget
          </FAQA>
          <ButtonTrace
            to="/education/memberships/basic"
            btn={3}
            className="mt-3"
          >
            Join Basic
          </ButtonTrace>
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center my-5">
          <FAQQ>What is the expert membership?</FAQQ>
          <FAQA>
            The expert membership is better for more experienced and established
            volume lash artists or volume lash artists looking to boost their
            lash game with two monthly videos
          </FAQA>
          <ButtonTrace
            to="/education/memberships/expert"
            btn={3}
            className="mt-3"
          >
            Join Expert
          </ButtonTrace>
        </div>
        <div className="col-md-12 d-flex flex-column justify-content-center my-5">
          <FAQQ>Can I purchase mini courses individually?</FAQQ>
          <FAQA>Yes!</FAQA>
          <ButtonTrace to="/education/videos" btn={3} className="mt-3">
            Shop Videos
          </ButtonTrace>
        </div>
      </div>
      {/* ----------------------------------------------------------------------- */}
    </Layout>
  )
}

export const query = graphql`
  query membershipsQuery {
    header: allFile(
      filter: { name: { eq: "online_membership_header_1200x800" } }
    ) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 1200) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    optionsBasic: allFile(filter: { name: { eq: "options_basic_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
    optionsExpert: allFile(filter: { name: { eq: "options_expert_600x600" } }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 100, maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  }
`

export default Memberships
